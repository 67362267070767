import React from "react";

/*** COMPONENTS ***/
import Main from "../../components/main";
import SEO from "../../components/seo";
import "../../styles/project.css";
import openspaceHeader from "../../images/openspace.png";

/*** CONSTANTS ***/
import * as ROUTES from "../../constants/routes";

function openGithubProceduralOpenSpace() {
  window.open(
    "https://github.com/johannagranstroem/TNM097-Image-Reproduction-and-Image-Quality",
    "_blank"
  );
}

const openspacePage = () => (
  <Main>
    <SEO title="OpenSpace" />
    <div class="project-container">
      <div class="project-grid-item-11">
        <img alt="Header-PostIt" src={openspaceHeader} />
        <div class="project-circle">
          <p class="project-header-text-black">OPENSPACE</p>
          <p class="project-header-text-grey">MASTER THESIS</p>
        </div>
      </div>
      <div class="project-grid-item-21">
        <p class="project-bulletpoint-text">Languages</p>
        <p class="project-bulletpoint-text"> &#9642; C++</p>
        <p class="project-bulletpoint-text"> &#9642; Python</p>
        <p class="project-bulletpoint-text"> &#9642; Lua</p>
      </div>
      <div class="project-grid-item-22">
        <p class="project-bulletpoint-text">Status</p>
        <p class="project-bulletpoint-text"> &#8226; Completed 2020-11-30</p>
      </div>
      <div class="project-grid-item-31">
        <p class="project-text-body-dark">
          My master thesis project is funded by NASA to make a software
          integration tool for a program called OpenSpace. The OpenSpace project
          is a collaboration with Linköping University and Imaging Institute at
          the University of Utah. My associate and I are currently developing a
          messaging protocol in C++. We are also developing a plugin in Python
          for the program Glue for OpenSpace to communicate with.
          <br />
          <br />
          You can checkout OpenSpace official{" "}
          <button
            class="project-text-link-style"
            onClick={() => openGithubProceduralOpenSpace()}
          >
            Github
          </button>
          !
          <br />
          <br />
        </p>
      </div>
      <div class="project-grid-item-61">
        <a class="project-text-back-to-projects" href={ROUTES.PROJECTS}>
          &#8592; Back to projects
        </a>
      </div>
    </div>
  </Main>
);

export default openspacePage;
